import React, { useEffect } from "react";
import "./gallery.css";
import img1 from "../../assets/images/gallery/1000099250.jpeg";
import img2 from "../../assets/images/gallery/1000099260.jpeg";
import img3 from "../../assets/images/gallery/1000099263.jpeg";
import img4 from "../../assets/images/gallery/1000099266.jpeg";
import img5 from "../../assets/images/gallery/1000099267.jpeg";
import img6 from "../../assets/images/gallery/1000099268.jpeg";
import img7 from "../../assets/images/gallery/1000099269.jpeg";
import img8 from "../../assets/images/gallery/1000099270.jpeg";
import img9 from "../../assets/images/gallery/1000099271.jpeg";
import img10 from "../../assets/images/gallery/1000099272.jpeg";
import img11 from "../../assets/images/gallery/1000099273.jpeg";
import img12 from "../../assets/images/gallery/1000099274.jpeg";
import img13 from "../../assets/images/gallery/1000099275.jpeg";
import img14 from "../../assets/images/gallery/1000099276.jpeg";
import img15 from "../../assets/images/gallery/1000099277.jpeg";
import img16 from "../../assets/images/gallery/1000099278.jpeg";

function Scroller({ children, speed = "default", direction = "left" }) {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className={`scroller`} data-speed={speed} data-direction={direction}>
      <div className="scroller__inner">{children}</div>
    </div>
  );
}

function Gallery() {
  return (
    <div className="imgWrapper">
      <div className="image">
        <img className="galleryImage" src={img10} alt="last year brunch" />
      </div>
      <div className="image">
        <img className="galleryImage" src={img9} alt="last year brunch" />
      </div>
      <div className="image">
        <img className="galleryImage" src={img13} alt="last year brunch" />
      </div>
      <div className="image">
        <img className="galleryImage" src={img16} alt="last year brunch" />
      </div>
      <div className="image">
        <img className="galleryImage" src={img5} alt="last year brunch" />
      </div>
      <div className="image">
        <img className="galleryImage" src={img6} alt="last year brunch" />
      </div>
    </div>
  );
}

export default Gallery;
