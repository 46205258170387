import { Outlet } from "react-router-dom";
import { Footer, HamburgerMenu, Nav, Navbar } from "../components/";

function Layout() {
  return (
    <div>
      {/* <Navbar /> */}
      <HamburgerMenu />
      {/* <Nav /> */}
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
