import React from "react";
import "./home.css";

import {
  Accordion,
  Gallery,
  Header,
  InfiniteHorizontalScroll,
  Showcase,
  Sponsorship,
} from "../../components";

const Home = () => {
  return (
    <>
      {/* <Showcase /> */}
      <Header />
      <section className="aboutSection" id="about">
        <div className="container">
          <div className="txtContent">
            <h1>About Us</h1>
            <h4>Cultivating Connections – the Africa Nominees Brunch</h4>
            <p>
              The Africa Nominees Brunch is an exclusive event curated by GUBA
              Enterprise to bring together Africa’s Grammy nominees, industry
              influencers and key stakeholders to celebrate Africa’s vibrant
              musical landscape. However, the brunch is more than a celebration;
              it’s a platform for connection, collaboration and fostering of
              relationships. <br />
              The maiden edition of the Africa Nominees Brunch occurred on
              February 4, 2023, in Los Angeles, on the eve of the 65th Grammy
              Awards. It allowed attendees to engage in thought-provoking
              discussions led by industry trailblazers, exploring topics ranging
              from the evolution of African music on the global stage to the
              impact of cultural diversity in shaping the industry's future.
              Special guests included Harvey Mason Jnr, the CEO of the Recording
              Academy and Panos A. Pany, President of the Recording Academy.
            </p>
            <div className="about-2">
              <h4>Heightened Relevance of the Africa Nominees Brunch</h4>
              <p>
                The addition of a dedicated category for African music at the
                66th Grammy Awards amplifies the relevance of the Africa
                Nominees Brunch. The brunch, already a celebration of African
                talent, now takes on added importance as it becomes a prelude to
                the recognition and acknowledgment that artists from the
                continent will receive on the grand stage of the Grammys.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="sponsorshipContainer">
        <div className="container">
          <Sponsorship />
        </div>
      </section> */}
      <section className="gallerySection" id="gallery">
        <div className="container">
          <Gallery />
        </div>
      </section>
      <section className="sponsorsScroll" id="sponsor">
        <div className="container">
          <div className="txtContent">
            <h1>Become A Sponsor</h1>
            <h4>An Opportunity to Elevate Your Brand</h4>
            <p>
              Align your brand with the spirit of unity, diversity, and
              excellence as we bring Africa’s musical brilliance to the
              forefront of the global stage. By sponsoring the GUBA Africa
              Nominees Brunch, your brand will not only be associated with a
              unique and memorable event but will also contribute to the
              promotion and elevation of African talent on the global music
              scene. We invite you to examine the range of sponsorship
              opportunities at the Africa Nominees Brunch.
            </p>
          </div>
          <div className="packages">
            {/* <h2>Sponsorship Packages</h2> */}
            <Accordion title="Platinum Sponsorship">
              <div className="accItem">
                <h5 className="accBullet">Premium Branding</h5>

                <ul className="accList">
                  <li>
                    Premium placement of the sponsor's logo on all event
                    collateral, including invitations, banners, and all
                    promotional materials.
                  </li>
                  <li>
                    Premium brand visibility on event signage, step-and-repeat
                    backgrounds, and multimedia presentations.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Exclusive VIP Experience</h5>
                <ul className="accList">
                  <li>
                    Special invitation to seven company representatives or
                    guests.
                  </li>
                  <li>
                    Exclusive VIP access to special guests and high-level
                    stakeholders.
                  </li>
                  <li>
                    Reserved seating with key industry influencers, artists, and
                    nominees.
                  </li>
                  <li>Special acknowledgment during the event.</li>
                  <li>
                    Opportunity for a company representative to give a 20-minute
                    address or presentation.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Marketing Collateral</h5>
                <ul className="accList">
                  <li>
                    Inclusion of the sponsor's promotional materials,
                    merchandise, or branded giveaways in event gift bags for
                    attendees.
                  </li>
                  <li>
                    Opportunity to provide branded materials or advertisements
                    in the event program.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Digital Promotion</h5>
                <ul className="accList">
                  <li>
                    Recognition as the Platinum Sponsor on the GUBA's official
                    website with a dedicated sponsor profile and link to the
                    sponsor's website.
                  </li>
                  <li>
                    Extensive promotion across GUBA's social media platforms,
                    showcasing the Platinum Sponsor's commitment to supporting
                    African musical talent.
                  </li>
                  <li>
                    Highlighted mentions and gratitude posts on social media
                    channels before, during, and after the event.
                  </li>
                  <li>Recognition in event highlight videos or promotional</li>
                  <li>Recognition in Pre- and Post- Event Coverage</li>
                  <li>
                    Acknowledgment as the Platinum Sponsor in event press
                    releases, articles, and recap materials distributed to media
                    outlets and industry publications.
                  </li>
                  <li>
                    Inclusion of the sponsor's logo in post-event thank-you
                    messages and acknowledgments.
                  </li>
                </ul>
                <p>Price: $100,000</p>
              </div>
            </Accordion>
            <Accordion title="Gold Sponsorship">
              <div className="accItem">
                <h5 className="accBullet">Premium Branding</h5>

                <ul className="accList">
                  <li>
                    Prominent placement of the sponsor's logo on event
                    collateral, including invitations, banners, and all
                    promotional materials.
                  </li>
                  <li>
                    Prominent brand visibility on event signage, step-and-repeat
                    backgrounds, and multimedia presentations.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Exclusive VIP Experience</h5>
                <ul className="accList">
                  <li>
                    Special invitation to five company representatives or
                    guests.
                  </li>
                  <li>
                    Reserved seating with key industry influencers, artists, and
                    nominees.
                  </li>
                  <li>Special acknowledgement during event.</li>
                  <li>
                    Opportunity for a company representative to give a 10-minute
                    address or presentation.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Marketing Collateral</h5>
                <ul className="accList">
                  <li>
                    Inclusion of the sponsor's promotional materials,
                    merchandise, or branded giveaways in event gift bags for
                    attendees.
                  </li>
                  <li>
                    Opportunity to provide branded materials or advertisements
                    in the event program.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Digital Promotion</h5>
                <ul className="accList">
                  <li>
                    Recognition as the Gold Sponsor on GUBA's official website
                    with a dedicated sponsor profile and link to the sponsor's
                    website.
                  </li>
                  <li>
                    Promotion across GUBA's social media platforms, showcasing
                    the Gold Sponsor's commitment to supporting African musical
                    talent.
                  </li>
                  <li>
                    Highlighted mentions and gratitude posts on social media
                    channels before, during, and after the event.
                  </li>
                  <li>
                    Recognition in event highlight videos or promotional content
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet">
                  Recognition in Pre- and Post-Event Coverage
                </h5>
                <ul className="accList">
                  <li>
                    Acknowledgment as Gold Sponsor in event press releases,
                    articles, and recap materials distributed to media outlets
                    and industry publications.
                  </li>
                  <li>
                    Inclusion of logo in post-event thank-you messages and
                    acknowledgments.
                  </li>
                </ul>
                <p>Price: $50,000</p>
              </div>
            </Accordion>
            <Accordion title="Silver Sponsorship">
              <div className="accItem">
                <h5 className="accBullet">Branding Visibility</h5>

                <ul className="accList">
                  <li>
                    Inclusion of logo on event collateral, including
                    invitations, banners, and select promotional materials.
                  </li>
                  <li>
                    Brand visibility on event signage, step-and-repeat
                    backgrounds, and multimedia presentations.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet">
                  {" "}
                  Enhanced Networking and Speaking Opportunity
                </h5>
                <ul className="accList">
                  <li>
                    Special invitation to three company representatives or
                    guests
                  </li>
                  <li>
                    Reserved seating with key industry influencers, artists, and
                    nominees.
                  </li>
                  <li>Acknowledgement during event..</li>
                  <li>
                    Opportunity for a company representative to give a 5-minute
                    address.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Marketing Collateral</h5>
                <ul className="accList">
                  <li>
                    Inclusion of the sponsor's promotional materials,
                    merchandise, or branded giveaways in event gift bags for
                    attendees.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Digital Promotion</h5>
                <ul className="accList">
                  <li>Promotion across GUBA's social media platforms.</li>
                  <li>
                    Mentions and gratitude posts on social media channels
                    before, during, and after the event.
                  </li>
                  <li>Recognition in Pre- and Post-Event Coverage</li>
                  <li>
                    Acknowledgment as Silver Sponsor in event press releases,
                    articles, and recap materials distributed to media outlets
                    and industry publications.
                  </li>
                  <li>
                    Inclusion of logo in post-event thank-you messages and
                    acknowledgments.
                  </li>
                </ul>
                <p>Price: $25,000</p>
              </div>
            </Accordion>
            <Accordion title="Bronze Sponsorship">
              <div className="accItem">
                <h5 className="accBullet">Brand Visibility</h5>

                <ul className="accList">
                  <li>
                    Inclusion of logo on event collateral, including
                    invitations, banners, and select promotional materials.
                  </li>
                  <li>
                    Brand visibility on event signage, step-and-repeat
                    backgrounds, and multimedia presentations.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Enhanced Networking</h5>
                <ul className="accList">
                  <li>
                    Special invitation to two company representatives or guests.
                  </li>

                  <li>
                    Reserved seating with key industry influencers, artists, and
                    nominees.
                  </li>
                  <li>Acknowledgment during event.</li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Marketing Collateral</h5>
                <ul className="accList">
                  <li>
                    Inclusion of the sponsor's promotional materials,
                    merchandise, or branded giveaways in event gift bags for
                    attendees.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet"> Digital Promotion</h5>
                <ul className="accList">
                  <li>Promotion across GUBA's social media platforms.</li>
                  <li>
                    Mentions and gratitude posts on social media channels
                    before, during, and after the event.
                  </li>
                </ul>
              </div>

              <div className="accItem">
                <h5 className="accBullet">
                  Recognition in Pre- and Post-Event Coverage
                </h5>
                <ul className="accList">
                  <li>
                    Acknowledgment as Bronze Sponsor in event press releases,
                    articles, and recap materials distributed to media outlets
                    and industry publications.
                  </li>
                  <li>
                    Inclusion of logo in post-event thank-you messages and
                    acknowledgments.
                  </li>
                </ul>
                <p>Price: $10,000</p>
              </div>
            </Accordion>
          </div>

          <InfiniteHorizontalScroll />
        </div>
      </section>
    </>
  );
};

export default Home;
