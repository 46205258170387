import React, { useState } from "react";
import "./HamburgerMenu.css"; // Import your CSS file for styling
import Nav from "../nav/Nav";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="hamburger-menu">
      {/* <a className="btn-white" href="https://www.surveymonkey.com/r/RXNFS8B">
        RSVP
      </a> */}
      <div className={`menu-icon ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      {isOpen && (
        // <div className="menu-items">
        //   <a href="#">Home</a>
        //   <a href="#">About</a>
        //   <a href="#">Services</a>
        //   <a href="#">Contact</a>
        // </div>
        <Nav />
      )}
    </div>
  );
};

export default HamburgerMenu;
