import React from "react";
import "./footer.css";
import { FaEnvelope, FaFacebook } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";

import { IoLogoTwitter } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer>
      <a href="#" className="footer__logo">
        The African Nominee Brunch
      </a>
      <ul className="permalinks">
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#event">Event</a>
        </li>
        <li>
          <a href="#gallery">Gallery</a>
        </li>
        <li>
          <a href="https://gubaenterprise.com" target="_blank">
            GUBA Enterprise
          </a>
        </li>
        <li>
          <a href="https://gubaawards.com" target="_blank">
            GUBA Awards
          </a>
        </li>
      </ul>
      <div className="contact">
        <Link className="icons" to="mailto:info@africannomineebrunch.com">
          <FaEnvelope className="icon" />
          info@africannomineebrunch.com
        </Link>
      </div>
      <div className="footer__socials">
        <a
          target="_blank"
          href="https://www.facebook.com/groups/475703410944780/?ref=share&mibextid=SDPelY"
        >
          <FaFacebook />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/theafricannomineebrunch?igsh=NjM1a2xyYmdwb2Ry&utm_source=qr"
        >
          <FiInstagram />
        </a>
        <a
          target="_blank"
          href="https://twitter.com/GUBADiasporaNwk?t=CA0lsc1NwhOerSJ9ojw8bQ&s=09"
        >
          <IoLogoTwitter />
        </a>
      </div>

      {/* <div className="footer__copyright">
        <small>&copy; GUBA. All rights reserved</small>
      </div> */}
      <div className="copyright">
        <div className="container">
          <div className="copy-name">
            &copy; {year} <Link to={"./"}> GUBA</Link> | Designed & developed by
            |
            <a
              href="http://www.veltetech.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {""} Velte Technology Solutions
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
