import React from "react";
import "./header.css";
import HeroVid from "../../assets/videos/African-Grammy-Nominees-Brunch-.mp4";
import headerImg from "../../assets/images/thumbnail_Logo.png";
import Address from "../../assets/images/info.png";

const Header = () => {
  return (
    <>
      <section className="headerSection">
        <div className="container">
          <div className="hor">
            <div className="imgContainer ver">
              <img src={headerImg} alt="The Africa Nominees Brunch" />
            </div>
            <div className="rsvp ver">
              <div className="location">
                {/* <h1>Feb. 3, 2024</h1> */}
                <h1>
                  Spring Palace, <br /> Beverly Hills.
                </h1>
                <p>Los Angeles</p>
                {/* <p>
                  Spring Palace, Beverly Hills – Los Angeles <br />
                  9:00am – 1:00pm (Pacific Time)
                </p> */}
              </div>
              {/* <a
                className="btn-white"
                href="https://www.surveymonkey.com/r/RXNFS8B"
              >
                RSVP
              </a> */}
            </div>
            {/* <div className="rsvp ver">
              <div className="location">
                <img src={Address} alt="location" />
              </div>
              <a
                className="btn-white"
                href="https://www.surveymonkey.com/r/RXNFS8B"
              >
                RSVP
              </a>
            </div> */}
          </div>
        </div>
        <div className="secondaryGradient" />
        <div className="yellowGradient" />
        <div className="video__overlayTop" />
        <div className="video__overlay" />
        <video
          id="hero-bgVid"
          className="header__bg-vid"
          playsInline
          autoPlay
          loop
          muted
          src={HeroVid}
          type="video/mp4"
        ></video>
      </section>
    </>
  );
};

export default Header;
