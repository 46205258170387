import React, { useEffect } from "react";
import "./scroll.css";
import logo1 from "../../assets/images/sponsors/TV3_Ghana_Logo.webp";
import logo2 from "../../assets/images/sponsors/Aline-Logo-Black.png";
import logo3 from "../../assets/images/sponsors/all-logo.png";
import logo4 from "../../assets/images/sponsors/Box-Logo-Tag.PNG";
import logo5 from "../../assets/images/sponsors/1.png";
import logo6 from "../../assets/images/sponsors/2.png";
import logo7 from "../../assets/images/sponsors/3.png";
import logo8 from "../../assets/images/sponsors/4.png";
import logo9 from "../../assets/images/sponsors/5.png";
import logo10 from "../../assets/images/sponsors/6.png";
import logo11 from "../../assets/images/sponsors/7.png";
import logo12 from "../../assets/images/sponsors/8.png";
import logo13 from "../../assets/images/sponsors/9.png";
import logo14 from "../../assets/images/sponsors/10.png";
import logo15 from "../../assets/images/sponsors/11.png";
import logo16 from "../../assets/images/sponsors/12.png";
import logo17 from "../../assets/images/sponsors/13.png";
import logo18 from "../../assets/images/sponsors/Independent_Media_Group_Africa.png";
import logo19 from "../../assets/images/sponsors/MOZO-TV-LOGO-Clear.png";
import logo20 from "../../assets/images/sponsors/Prime-Tech-Video.jpg";

const sponsors = [
  {
    logo: logo1,
  },
  {
    logo: logo2,
  },
  {
    logo: logo3,
  },
  {
    logo: logo4,
  },
  {
    logo: logo5,
  },
  {
    logo: logo6,
  },
  {
    logo: logo7,
  },
  {
    logo: logo8,
  },
  {
    logo: logo9,
  },
  {
    logo: logo10,
  },
  {
    logo: logo11,
  },
  {
    logo: logo12,
  },
  {
    logo: logo13,
  },
  {
    logo: logo14,
  },
  {
    logo: logo15,
  },
  {
    logo: logo16,
  },
  {
    logo: logo17,
  },
  {
    logo: logo18,
  },
  {
    logo: logo19,
  },
  {
    logo: logo20,
  },
];

function Scroller({ children, speed = "default", direction = "left" }) {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className={`scroller`} data-speed={speed} data-direction={direction}>
      <div className="scroller__inner">{children}</div>
    </div>
  );
}

function InfiniteHorizontalScroll() {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>Infinite Scroll Animation</h1> */}

      <Scroller speed="slow">
        <ul className="tag-list scroller__inner">
          {sponsors.map((logo, index) => (
            <li key={index}>
              <img className="sponsorIcon" src={logo.logo} alt="sponsor" />
            </li>
          ))}
        </ul>
      </Scroller>

      {/* <Scroller direction="right" speed="slow">
        <>
          <img src="https://i.pravatar.cc/150?img=1" alt="" />
          <img src="https://i.pravatar.cc/150?img=2" alt="" />
          <img src="https://i.pravatar.cc/150?img=3" alt="" />
          <img src="https://i.pravatar.cc/150?img=4" alt="" />
          <img src="https://i.pravatar.cc/150?img=5" alt="" />
          <img src="https://i.pravatar.cc/150?img=6" alt="" />
        </>
      </Scroller> */}
    </div>
  );
}

export default InfiniteHorizontalScroll;
