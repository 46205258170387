import React from "react";
import "./nav.css";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook, BiImage } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageRoundedDots } from "react-icons/bi";
import { useState } from "react";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#gallery"
        onClick={() => setActiveNav("#gallery")}
        className={activeNav === "#gallery" ? "active" : ""}
      >
        <BiImage />
      </a>
      <a
        href="#sponsor"
        onClick={() => setActiveNav("#sponsor")}
        className={activeNav === "#sponsor" ? "active" : ""}
      >
        <RiServiceLine />
      </a>
    </nav>
  );
};

export default Nav;
